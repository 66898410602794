// Poppins
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,600&display=swap");
// Syne
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body,
html {
  overflow-x: hidden;
}

$mainColor: #00bcd4;
$secondaryColor: #b2ebf2;
$whiteColor: #fff;
$blackColor: #070337;
$gray: #b7b9bd;

input {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

.alert-custom {
  padding: 10px 0;
  text-align: center;
  background-color: #ffecc0;
  color: $blackColor;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  a {
    @media screen and (max-width: 800px) {
      width: 80%;
    }
    span.text {
      font-size: 20px;
      color: #0f1820 !important;
      text-decoration: underline !important;
      @media screen and (max-width: 500px) {
        font-size: 15px !important;
      }
      @media screen and (max-width: 300px) {
        font-size: 10px !important;
      }
    }
  }
}

.title {
  text-transform: capitalize;
}

.display-flex-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.default-padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.default-padding-top {
  padding-top: 4rem;
}

.default-padding-bottom {
  padding-bottom: 4rem;
}

.default-margin {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.default-margin-top {
  margin-top: 4rem;
}

.sec-title {
  font-size: 48px;
  font-weight: 700;
  color: $blackColor;
  font-family: "Syne", sans-serif !important;
}

.sec-sub {
  font-family: "Syne", sans-serif !important;
}

.cs_btn {
  display: flex;
  align-items: center;
  border: 1px solid $mainColor;
  column-gap: 10px;
  padding: 12px 30px;
  background-color: #fff;
  border-radius: 4px;
  transition: all 0.3s linear;

  &:hover {
    background: linear-gradient(
      94.06deg,
      #236bf6 0%,
      #a027d4 54.48%,
      #f5716b 100%
    );
    color: $whiteColor;
  }

  &.primary {
    background: linear-gradient(
      94.06deg,
      #236bf6 0%,
      #a027d4 54.48%,
      #f5716b 100%
    );
    color: $whiteColor;
  }
}

$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;

.navbar {
  padding: 20px 4rem;
  transition: all 0.3s ease-in;
  z-index: 9999;
  position: relative;

  &.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffffc9;
    box-shadow: 0 0 10px #cdcdcd;
    backdrop-filter: blur(8px);
  }

  @media screen and (max-width: $breakpoint-xl) {
    padding: 20px 2rem;
  }

  .brand-logo {
    width: 48px;
    height: auto;
  }

  .searchBarICON {
    cursor: pointer;
    width: 40px;
    padding: 8px;
    text-align: center;
  }

  .drop-down-menu {
    position: absolute;
    top: 4rem;
    left: 19px;
    background-color: rgba(205, 205, 205, 0.6588235294);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: 9999;
    padding: 30px 2rem;
    visibility: hidden;
    border-radius: 4px;
    display: none;

    &.active {
      visibility: visible;
      display: block;
      height: fit-content;
    }
  }

  .position-relative {
    .menu-langs {
      position: absolute;
      left: 0;
      top: 175%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 10;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 3px;
      padding: 0 10px 0;
      box-shadow: 0 0 10px #777;
      display: none;

      .menu-langs-title {
        color: rgba(0, 0, 0, 0.54);
        position: relative;
        width: 100%;
        margin-bottom: 5px !important;
        font-size: 12px;
        margin: 10px 0;
      }

      .lan {
        position: relative;
        padding: 5px 0;
        border-bottom: 1px solid #cdcdcd;
        cursor: pointer;

        > img {
          width: 18px !important;
        }
      }

      &.active {
        display: block;
      }
    }
  }

  .settings {
    font-size: 1rem;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    padding-left: calc(1rem + 7px);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0.3px;
      height: 100%;
      background-color: $mainColor;
    }
  }

  .nav-items {
    display: inline-block;

    .nav-item {
      padding-left: 25px;
      text-decoration: none;
      font-size: 18px;
      color: $blackColor;
      text-transform: capitalize;

      @media screen and (max-width: $breakpoint-lg) {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }

    @media screen and (max-width: $breakpoint-xl) {
      display: none;
    }
  }

  .icon {
    padding: 8px;
    border: 1px solid $mainColor;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      background-color: $mainColor;
      color: #fff;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 20px;

    .media-icons {
      display: grid;
      grid-template-columns: repeat(3, 70px);
      gap: 10px;

      @media screen and (max-width: $breakpoint-xl) {
        display: none;
      }
    }
  }

  .bars-icon {
    cursor: pointer;

    @media screen and (min-width: $breakpoint-xl) {
      display: none;
    }
  }
}

#home {
  position: relative;
  background-image: url(../images/hero/bg.webp);
  background-size: cover;
  background-position: top;

  .des-1 {
    width: 400px;
    position: absolute;
    left: 0;
    top: 19%;
    z-index: -1;
  }

  .tech-1,
  .tech-2,
  .tech-3,
  .tech-4,
  .tech-5 {
    position: absolute;
    width: 100px;
    z-index: 1;
  }

  .tech-1 {
    right: 42%;
    top: 17%;
    animation: moving 1s 1s infinite alternate;
  }
  .tech-2 {
    right: 39%;
    top: 42%;
    transform: rotate(7deg);
    animation: moving 1.7s 1s infinite alternate;
  }
  .tech-3 {
    right: 40%;
    top: 70%;
    transform: rotate(-5deg);
    animation: moving 2s 1s infinite alternate;
  }
  .tech-4 {
    right: 9%;
    top: 15%;
    animation: moving 1.5s 1s infinite alternate;
  }
  .tech-5 {
    right: 9%;
    top: 55%;
    animation: moving 3s 0.7s infinite alternate;
  }
  @keyframes moving {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    100% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }

  .hero-text {
    h1 {
      color: #070337;
      font-size: 72px;
      line-height: 85px;
      margin-bottom: 30px;
      font-weight: 800;

      span {
        background: -webkit-linear-gradient(
          355.94deg,
          #236bf6 0%,
          #a027d4 54.48%,
          #f5716b 100%
        );
        background: linear-gradient(
          94.06deg,
          #236bf6 0%,
          #a027d4 54.48%,
          #f5716b 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .sub {
      color: #808080;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }
  }

  figure {
    max-width: 500px;
    float: right;
    margin-left: 20px;
    animation: moving 1.4s 1s infinite alternate;
  }

  .product-search-form {
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;

    .bar-search {
      width: 100%;

      input {
        width: 100%;
        padding: 0 22px;
        font-weight: 600;
        font-size: 18px;
        color: $gray;
        padding: 10px 0;
        text-indent: 10px;

        &::placeholder {
          font-size: 15px;
        }
      }
    }
    .icon {
      position: absolute;
      right: 22px;
      background: #fffcfcad;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-btn {
      cursor: pointer;
    }
  }
}

.card {
  width: 100%;
  overflow: hidden;

  &:hover img {
    filter: blur(13px);
  }

  &:hover .buttons {
    opacity: 1 !important;
  }

  img,
  .buttons {
    transition: all 0.3s linear;
  }

  .pr__card-header {
    position: relative;
    background-color: transparent;

    .buttons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s linear;
      opacity: 0;
    }
  }

  .card-body {
    .card-title {
      color: $blackColor;
      font-size: 30px;
      line-height: 30px;
      letter-spacing: -0.3px;
      font-weight: 700;
    }

    .pr__info {
      font-weight: 800;
      color: $blackColor;
    }

    .card-text {
      line-height: 32px;
      color: $blackColor;
    }

    .pr__price {
      font-size: 20px;
      color: $blackColor;
    }
  }
}

#why {
  background-image: url(../images/hero/sec-bg.jpg);
  background-size: cover;
  background-position: top;

  .features {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 20px;
    .why__card {
      width: 100%;
      list-style-type: none;
      padding: 30px 24px;

      &:first-child {
        background-color: #fff1f1;
        .image {
          background-color: #ff3d4b;
        }
      }
      &:nth-child(2) {
        background-color: #f1effc;
        .image {
          background-color: #815efb;
        }
      }
      &:nth-child(3) {
        background-color: #e3fff6;
        .image {
          background-color: #00d174;
        }
      }
      &:last-child {
        background-color: #fff6e5;
        .image {
          background-color: #febb39;
        }
      }

      .image {
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        padding: 10px;
      }
      h4 {
        font-weight: 800;
        color: $blackColor;
      }
      p {
        color: $blackColor;
      }
    }
  }
}

#free {
  .freebiesProducts {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    gap: 10px;
    .card {
      width: 100%;
    }
  }

  .side {
    background: #2eb3e4 url(../images/products/pattern-featured.webp);
    background-position: top;
    border-radius: 10px;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    display: flex;
    padding: 11rem 16px;
  }
}
.fr__card {
  border: 0;
  padding: 0;
  margin: 0;
  position: relative;
  background: 0 0;
  overflow: hidden;

  .fr__card-header {
    box-shadow: 0 25px 20px -21px rgba(0, 0, 0, 0.57);
  }

  .card-body {
    padding: 5px;
    border-radius: 10px;
  }

  img {
    border-radius: 8px;
  }

  h4 {
    margin-top: 10px;
    font-size: 18px;
  }
}
#technologies {
  position: relative;
  .techs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    .image {
      width: 100%;
    }
  }
}

#features {
  position: relative;

  .features {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    .featured-icon {
      width: 90px;
      margin-bottom: 30px;
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 5%;
    //   left: -15%;
    //   width: 130%;
    //   height: 100%;
    //   transform: rotate(3deg);
    //   background: linear-gradient(
    //     200deg,
    //     #236bf6 0%,
    //     #a027d4 54.48%,
    //     #f5716b 100%
    //   );
    // }

    .featured-item {
      box-shadow: 1px 0px 0 #00bcd4, 0px 1px 1px #b2ebf2;
      padding: 40px 30px;
      position: relative;
      border-radius: 50px;
      overflow: hidden;
      z-index: 9;
      background: $whiteColor;
    }
    .featured-title {
      font-size: 20px;
      color: $blackColor;
    }
  }
}
