$mainColor: #00bcd4;
$secondaryColor: #b2ebf2;
$whiteColor: #fff;
$blackColor: #070337;
$gray: #b7b9bd;

$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;

#home {
  .tech-1,
  .tech-2,
  .tech-3,
  .tech-4,
  .tech-5 {
    @media screen and (max-width: $breakpoint-md) {
      display: none;
    }
  }

  .col-md-6:first-child {
    @media screen and (max-width: $breakpoint-md) {
      width: 100%;
      margin: auto;
      text-align: center;
    }
  }

  .col-md-6:last-child {
    @media screen and (max-width: $breakpoint-md) {
      display: none;
    }
  }

  .hero-text {
    h1 {
      @media screen and (max-width: $breakpoint-lg) {
        font-size: 44px;
        line-height: 65px;
      }
      @media screen and (max-width: $breakpoint-xs) {
        font-size: 36px;
        line-height: 49px;
      }
    }

    .sub {
      @media screen and (max-width: $breakpoint-xl) {
        font-size: 14px;
      }
    }
  }

  .product-search-form {
    @media screen and (max-width: $breakpoint-md) {
      width: 80%;
      margin: auto;
    }
  }
}

#free {
  .side {
    @media screen and (max-width: $breakpoint-xl) {
      display: none;
    }
  }
  .freebiesProducts {
    @media screen and (max-width: $breakpoint-xl) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
  .col-9 {
    @media screen and (max-width: $breakpoint-xl) {
      width: 100%;
    }
  }
}
