html[dir="rtl"] {
  * {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }

  .settings {
    padding: 0 20px 0 0;
    &::before {
      content: "";
      position: absolute;
      top: 0 !important;
      left: auto !important;
      right: 0;
    }
  }

  .navbar {
    .position-relative .menu-langs {
      left: 35px;
    }
    .drop-down-menu {
      left: auto;
      right: 19px;
    }
  }

  #about {
    .description {
      font-size: 18px;
    }
  }

  #wa {
    .wa-icon {
      left: auto;
      right: 20px;
    }

    .wa-txt {
      right: 123px;
      left: auto;
      &.active {
        left: auto;
        right: 105px;
      }
    }
  }

  footer {
    .text-white,
    a,
    p {
      text-align: right !important;
    }
  }
}
